<template>
    <div class="not-found">
        <img :src="require('@/assets/img/not-found/no-access.png')" alt="no-access">
        <div class="not-found__text">У Вас нет доступа к этому разделу</div>
        <v-btn class="evi-button-green evi-button-green--fill" @click="$router.go(-1)">
            Вернуться назад
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "NoAccess"
    }
</script>

<style lang="scss" scoped>
    .not-found {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 30px;

        &__text {
            font-weight: 600;
            font-size: 36px;
            line-height: 29px;
            color: $evi-headline-color;
            margin-top: 34px;
            margin-bottom: 34px;
        }
    }
</style>
